import * as api from '../../api/api';
import { Options } from '../../api/api';
import { DEFAULT_STORE_AVAILABILITY, EMPTY_STORE } from '../../constants';
import { Store, StoreAvailability } from '../../types/store';
import * as environment from '../../utils/environment';
import { camelToPascal, isZipCode } from './utils';

export const EXTERNAL_STORES_API = {
    uri: '/stores/api'
};

function getBaseUrl() {
    return  environment.get().prod ? 'www' : 'wwwqa';
}

export const getStoreUrl = (id: Store['Id']) => {
    return `https://${getBaseUrl()}.carmax.com${EXTERNAL_STORES_API.uri}/${id}`;
};

export const getStoreAvailabilityUrl = (id: Store['Id']) => {
    return `https://${getBaseUrl()}.carmax.com${EXTERNAL_STORES_API.uri}/status/${id}`;
};

export const getStoresByKeywordUrl = (keyword: string, take: number) => {
    return `https://${getBaseUrl()}.carmax.com${EXTERNAL_STORES_API.uri}/keyword/${keyword}/${take}`;
};

export const getStore = (id: Store['Id'], done: (store: Store) => any) => {
    const options: Options = {
        url: getStoreUrl(id),
    };
    api.get(
        options,
        function success(res) {
            const store = camelToPascal(res) as Store;
            done(store);
        },
        function error(e) {
            console.error('Error fetching nearest store: ', e);
            done(EMPTY_STORE);
        }
    );
};

export const getStoreAvailability = (id: Store['Id'], done: (storeStatus: StoreAvailability) => any) => {
    const options: Options = {
        url: getStoreAvailabilityUrl(id),
    };
    api.get(
        options,
        function success(res) {
            const store = camelToPascal(res) as StoreAvailability;
            done(store);
        },
        function error(e) {
            console.error('Error fetching store availability: ', e);
            done(DEFAULT_STORE_AVAILABILITY);
        }
    );
};

export const getStoresByKeyword = (storeZip: string, keyword: string, take: number, done: (stores: Store[] | null, allStores: Store[] | null) => void) => {
    const options: Options = {
        url: getStoresByKeywordUrl(keyword, take),
    };
    api.get(
        options,

        function success(res) {
            let storesPascal = res.map((r: Store) => camelToPascal(r));
            let storesWithoutCBC = storesPascal.filter((store: Store) => !store.IsCarBuyingCenter );
            if (isZipCode(storeZip) && !isZipCode(keyword)) {
                // If previous fetch was not by zipcode,
                // fetch all stores by zipcode to fill in the distances
                getStoresByKeyword(storeZip, storeZip, 9999, (zipStores: Store[] | null) => {
                    if (zipStores) {
                        storesWithoutCBC = storesWithoutCBC.map((sp: Store) => {
                            const found = zipStores.find((zs: Store) => zs.Id === sp.Id);
                            if (found) {
                                sp.DistanceInMiles = found.DistanceInMiles;
                            }

                            return sp;
                        });
                    }
                    done(storesWithoutCBC, zipStores);
                });
            } else {
                done(storesWithoutCBC, null);
            }
        },
        function error(e) {
            console.error('Error fetching nearest store: ', e);
            done(null, null)
        }
    );
};
