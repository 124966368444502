import * as constants from '../../constants';
import { DEFAULT_STORE_AVAILABILITY, EMPTY_STORE } from '../../constants';
import { content, icons } from '../../content';
import * as cookie from '../../storage/cookie';
import * as storage from '../../storage/storage';
import * as storesApi from './storesApi';
import NearestStoreMenu from './NearestStoreMenu';
import { ANALYTICS_CONSTANTS, track } from '../../utils/analytics';
import { IPayload } from '../../types/analytics';
import { Store, StoreAvailability } from '../../types/store';

interface ISubscribeToStoreUpdatesEventDetail {
    done: (store: Store) => any;
}
export type SubscribeToStoreUpdatesEventDetail = ISubscribeToStoreUpdatesEventDetail;

interface IOpenStoreSelectionModal {
    done?: () => any;
    stateOrZip: string|number;
}
export type OpenStoreSelectionModalEventDetail = IOpenStoreSelectionModal;

interface ISetStoreEventDetail {
    done?: () => any;
    store: Store;
}
export type SetStoreEventDetail = ISetStoreEventDetail;

interface ISetStoreByIdEventDetail {
    distanceInMiles: number;
    done?: (store: Store) => any;
    id: Store['Id'];
    reload?: boolean;
}
export type SetStoreByIdEventDetail = ISetStoreByIdEventDetail;

export interface INearestStore {
    headerMenu: NearestStoreMenu;
    footerMenu: NearestStoreMenu;
    nearestStore: Store;
    initialRenderingComplete: boolean;
    flyoutOpen: { [index: string]: boolean };
    footerFlyoutOpen: boolean;
    storeUpdateSubscribers: SubscribeToStoreUpdatesEventDetail['done'][];
    init(): void;
    attachSubscribeToStoreUpdatesEventListener(): void;
    attachSetStoreEventListener(): void;
    attachSetStoreByIdEventListener(): void;
    attachSetStoreByIdOnloadEventListener():void;
    attachOpenStoreSelectionModalListener(): void;
    handleLoad(store: Store): void;
    load(): void;
    toggle(isTarget: boolean, type: 'header' | 'footer'): void;
    handleButtonKeydown(event: KeyboardEvent, string: 'header' | 'footer'): void;
    handleMenuKeydown(event: KeyboardEvent, string: 'header' | 'footer'): void;
    handleMouseover(): void;
    handleClickOutside(type: 'header' | 'footer'): void;
    renderHeaderStoreName(): void;
    renderFooterStoreName(): void;
    renderHeaderStoreAlerts(hasAlert: boolean): void;
    renderFooterStoreAlerts(hasAlert: boolean): void;
    renderAll(force: boolean): void;
    update(store: Store): void;
}

export default class NearestStore implements INearestStore {
    private _nearestStore: Store;
    private _storeAvailability: StoreAvailability;
    headerMenu: NearestStoreMenu;
    footerMenu: NearestStoreMenu;
    initialRenderingComplete: boolean;
    flyoutOpen: { [index: string]: boolean };
    footerFlyoutOpen: boolean;
    storeUpdateSubscribers: SubscribeToStoreUpdatesEventDetail['done'][];
    menuLocation: string;

    constructor() {
        this._nearestStore = EMPTY_STORE;
        this._storeAvailability = DEFAULT_STORE_AVAILABILITY;
        this.initialRenderingComplete = false;
        this.flyoutOpen = { header: false, footer: false };
        this.footerFlyoutOpen = false;
        this.storeUpdateSubscribers = [];
        this.menuLocation = '';

        this.attachSubscribeToStoreUpdatesEventListener();
        this.attachSetStoreEventListener();
        this.attachSetStoreByIdEventListener();
        this.attachSetStoreByIdOnloadEventListener();
        this.attachOpenStoreSelectionModalListener();

        this.headerMenu = new NearestStoreMenu('header', this);
        this.footerMenu = new NearestStoreMenu('footer', this);
    }

    public init(): void {
        this.load();
    }

    get nearestStore(): Store {
        return this._nearestStore;
    }

    set nearestStore(store: Store) {
        this._nearestStore = store;

        this.storeUpdateSubscribers.forEach((subscriber: SubscribeToStoreUpdatesEventDetail['done']) => {
            subscriber(store);
        });
    }

    get storeAvailability(): StoreAvailability {
        return this._storeAvailability;
    }

    set storeAvailability(storeAvailability: StoreAvailability) {
        this._storeAvailability = storeAvailability;
    }

    public attachSubscribeToStoreUpdatesEventListener(): void {
        document.documentElement.addEventListener(constants.SUBSCRIBE_TO_STORE_UPDATES_CUSTOM_EVENT, ((
            event: CustomEvent
        ) => {
            const eventDetail: SubscribeToStoreUpdatesEventDetail = event.detail;

            if (eventDetail.done && typeof eventDetail.done === 'function') {
                if (this.nearestStore.Id) {
                    eventDetail.done(this.nearestStore);
                }
                this.storeUpdateSubscribers.push(eventDetail.done);
            }
        }) as EventListener);
    }

    public attachSetStoreEventListener(): void {
        document.documentElement.addEventListener(constants.SET_STORE_CUSTOM_EVENT, ((event: CustomEvent) => {
            const eventDetail: SetStoreEventDetail = event.detail;
            this.update(eventDetail.store);
            if (eventDetail.done && typeof eventDetail.done === 'function') {
                eventDetail.done();
            }
        }) as EventListener);
    }

    public attachOpenStoreSelectionModalListener(): void {
        document.documentElement.addEventListener(
            constants.OPEN_STORE_SELECTION_MODAL_EVENT, ((event: CustomEvent) => {
            const eventDetail: OpenStoreSelectionModalEventDetail = event.detail;

            const customEvent = new CustomEvent(content.STORE_CHOOSER.EVENT_NAMES.headerKeywordInput, { detail: { keyword: eventDetail.stateOrZip }});
            dispatchEvent(customEvent);
        }) as EventListener);
    }

    public attachSetStoreByIdEventListener(): void {
        document.documentElement.addEventListener(constants.SET_STORE_BY_ID_CUSTOM_EVENT, ((event: CustomEvent) => {
            this.setStoreByID(true,event)

        }) as EventListener);
    }

    public setStoreByID(sendDataToAdobeAnalytics:boolean, event: CustomEvent):void {

            const eventDetail: SetStoreByIdEventDetail = event.detail;
            const currentStore = cookie.getStoreId();



            if(eventDetail.id && currentStore !== eventDetail.id) {

                storesApi.getStore(eventDetail.id, (store: Store) => {
                    if (store && store.Id) {
                        cookie.setStoreCookie(store);
                        store.DistanceInMiles = eventDetail.distanceInMiles ? eventDetail.distanceInMiles : 0;
                        this.update(store);
                    }
                    if (eventDetail.done && typeof eventDetail.done === 'function') {
                        eventDetail.done(store);
                    }
                });
            }
    }

    /**
     * @description set store onload without sending data to adobe analytics
     */
    public attachSetStoreByIdOnloadEventListener(): void {
        document.documentElement.addEventListener(constants.SET_STORE_BY_ID_ONLOAD_CUSTOM_EVENT, ((event: CustomEvent) => {
            this.setStoreByID(false,event)
        }) as EventListener);
    }

    public handleLoad(store: Store): void {
        this.nearestStore = store;

        this.renderHeaderStoreName();
        this.renderFooterStoreName();
        this.renderHeaderStoreAlerts(!!this.nearestStore.AlertMessage);
        this.renderFooterStoreAlerts(!!this.nearestStore.AlertMessage);

        if (this.initialRenderingComplete) {
            this.renderAll(true);
        }
    }

    public load(): void {
        let nearestStoreId = cookie.getStoreId();

        function validateCachedStoreExists(cStore: Store): boolean {
            return cStore && cStore.Id && cStore.Hours ? true : false;
        }

        if (!nearestStoreId) {
            this.handleLoad(EMPTY_STORE);
        } else {
            let cachedStore: Store | null = storage.getStore(nearestStoreId);

            if (cachedStore && validateCachedStoreExists(cachedStore)) {
                this.handleLoad(cachedStore);
            } else {
                storesApi.getStore(nearestStoreId, (store: Store) => {
                    storage.setStore(store);
                    this.handleLoad(store);
                });
            }

            // Ideally this would be combined with getStore request
            // But the store data caching used both here and in the stores api would make it difficult.
            storesApi.getStoreAvailability(nearestStoreId, (availability: StoreAvailability) => {
                this.storeAvailability = availability;
                this.handleLoad(this.nearestStore);
            });
        }
    }

    public toggle(isTarget: boolean, type: string): void {
        this.menuLocation = type;
        if (isTarget) {
            if (!this.initialRenderingComplete) {
                this.renderAll();
                this.initialRenderingComplete = true;
            }
            type === 'header' ? this.headerMenu.handleOpenButtonClick() : this.footerMenu.handleOpenButtonClick();
        }

        function closeStoreMenuOnInputSubmit(menuToClose: NearestStoreMenu) {
            menuToClose.handleCloseButtonClick();
        }

        const activeMenu: NearestStoreMenu = type === 'header' ? this.headerMenu : this.footerMenu;
        const searchHeaderInputForm: HTMLFormElement | null = document.querySelector(
            `#${content.HEADER_MY_STORE.storeSearchFormId}`
        );
        const searchFooterInputForm: HTMLFormElement | null = document.querySelector(
            `#${content.FOOTER_MY_STORE.storeSearchFormId}`
        );
        if (searchHeaderInputForm) {
            searchHeaderInputForm.addEventListener('submit', (e: Event) => {
                const formData = new FormData(searchHeaderInputForm);
                const keywordInput = formData.get('keyword');
                const keywordInputEvent = new CustomEvent(content.STORE_CHOOSER.EVENT_NAMES.headerKeywordInput, {
                    detail: { keyword: keywordInput }
                });
                dispatchEvent(keywordInputEvent);
                closeStoreMenuOnInputSubmit(activeMenu);
            });
        }
        if (searchFooterInputForm) {
            searchFooterInputForm.addEventListener('submit', (e: Event) => {
                const formData = new FormData(searchFooterInputForm);
                const keywordInput = formData.get('keyword');
                const keywordInputEvent = new CustomEvent(content.STORE_CHOOSER.EVENT_NAMES.headerKeywordInput, {
                    detail: { keyword: keywordInput }
                });
                dispatchEvent(keywordInputEvent);
                closeStoreMenuOnInputSubmit(activeMenu);
            });
        }
    }

    public handleButtonKeydown(event: KeyboardEvent, type: 'header' | 'footer'): void {
        type === 'header'
            ? this.headerMenu.handleOpenButtonKeydown(event)
            : this.footerMenu.handleOpenButtonKeydown(event);
    }

    public handleMenuKeydown(event: KeyboardEvent, type: 'header' | 'footer'): void {
        type === 'header' ? this.headerMenu.handleMenuKeydown(event) : this.footerMenu.handleMenuKeydown(event);
    }

    public handleMouseover(): void {
        if (!this.initialRenderingComplete) {
            this.renderAll();
            this.initialRenderingComplete = true;
        }
    }

    public handleClickOutside(type: 'header' | 'footer'): void {
        type === 'header' ? this.headerMenu.handleClickOutside() : this.footerMenu.handleClickOutside();
    }

    public renderHeaderStoreName(): void {
        let headerButtonEl: HTMLElement | null = document.querySelector(`#${content.HEADER_MY_STORE.buttonId}`);

        // Inject new html so that other repositories do not have to rebuild/deploy html changes.
        if (headerButtonEl) {
            headerButtonEl.classList.add('visible');
            if (this.nearestStore.Id) {
                headerButtonEl.innerHTML = '';
                let buttonHtml = headerButtonEl.innerHTML;
                let locationIconHtml = `<svg class="header-footer-icon" width="40" height="40"
                    viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.29 11c0-5.35 4.36-9.71 9.71-9.71 5.35 0 9.71 4.12 9.71 9.71 0 5.569-6.153 9.57-8.207 10.905l-.023.015c-.45.29-.97.44-1.48.44s-1.03-.15-1.48-.44C8.48 20.6 2.29 16.35 2.29 11Zm8.97 9.711.03.019c.43.28.99.28 1.42 0 1.87-1.21 7.58-4.91 7.58-9.73 0-4.57-3.72-8.29-8.29-8.29-4.57 0-8.29 3.48-8.29 8.29 0 4.785 5.651 8.472 7.55 9.711ZM8.29 11c0-2.05 1.66-3.71 3.71-3.71s3.71 1.66 3.71 3.71-1.66 3.71-3.71 3.71S8.29 13.05 8.29 11Zm1.42 0c0 1.26 1.03 2.29 2.29 2.29s2.29-1.03 2.29-2.29S13.26 8.71 12 8.71 9.71 9.74 9.71 11Z" />
                    <circle class="hidden header-footer-location-icon-alert-circle" cx="19" cy="4" r="4"
                        stroke="white" stroke-width="1" />
                </svg>`;
                let appendHtml = `<div class="header-footer-flyout-button-text-container">
                <span id="header-my-store-button-zipCode">Your store for ${this.nearestStore.ZipCode}</span>
                    <div class="header-find-your-store-container">
                        <span id="header-my-store-button-text">${this.nearestStore.Name}</span>
                        <svg class="header-footer-icon--secondary" width="20" height="15" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M7 10l5 5 5-5z"></path>
                            <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                    </div>
                </div>`;
                headerButtonEl.innerHTML = locationIconHtml + buttonHtml + appendHtml;
            }
        } else {
            let headerMyStoreButton = <HTMLElement>(
                document.querySelector(
                    `#${content.HEADER.id} #${content.HEADER_MY_STORE.buttonId} > .${content.HEADER_MY_STORE.iconClass}`
                )
            );
            let headerMyStoreSection = <HTMLElement>(
                document.querySelector(`#${content.HEADER.id} .${content.HEADER_MY_STORE.sectionClass}`)
            );

            if (headerMyStoreButton) {
                headerMyStoreButton.classList.add(content.CLASSNAMES.visible);
            }
            if (headerMyStoreSection) {
                headerMyStoreSection.classList.add(content.HEADER_MY_STORE.sectionClassNoStore);
            }
        }
    }

    public renderFooterStoreName(): void {
        let footerFlyoutButtonTextEl = document.querySelector(
            `#${content.FOOTER.id} #${content.FOOTER_MY_STORE.buttonTextId}`
        );
        if (footerFlyoutButtonTextEl && this.nearestStore.Id) {
            footerFlyoutButtonTextEl.textContent = this.nearestStore.Name;
        }
    }

    private renderHeaderAlertInfoIcon(hasAlert: boolean): void {
        let existingStoreAlertContainerEl = <HTMLElement>(
            document.querySelector(`#${content.HEADER.id} .${content.HEADER_MY_STORE.alertInfoIconContainerClass}`)
        );

        if (hasAlert) {
            let headerMyStoreButtonEl = <HTMLElement>(
                document.querySelector(`#${content.HEADER.id} #${content.HEADER_MY_STORE.buttonId}`)
            );

            if (!existingStoreAlertContainerEl && headerMyStoreButtonEl) {
                let firstChildEl = headerMyStoreButtonEl.firstChild;
                let storeAlertContainerEl = document.createElement('span');
                storeAlertContainerEl.classList.add(content.HEADER_MY_STORE.alertInfoIconContainerClass);
                storeAlertContainerEl.innerHTML = icons.buildAlertInfoIcon(content.HEADER_MY_STORE.alertInfoIconClass);
                headerMyStoreButtonEl.insertBefore(storeAlertContainerEl, firstChildEl);
            }
        } else {
            if (existingStoreAlertContainerEl && existingStoreAlertContainerEl.parentNode) {
                existingStoreAlertContainerEl.parentNode.removeChild(existingStoreAlertContainerEl);
            }
        }
    }

    private renderAlertLocationIcon(options: { hasAlert: boolean; type: 'HEADER' | 'FOOTER' }): void {
        let myStoreConstantName = options.type === 'HEADER' ? 'HEADER_MY_STORE' : 'FOOTER_MY_STORE';
        let svgCircleAlertEls = document.querySelectorAll(
            `#${(content[options.type] as any).id} .${
                ((content as any)[myStoreConstantName] as any).locationAlertCircleClass
            }`
        );
        let myStoreButton = document.querySelector(`#${((content as any)[myStoreConstantName] as any).buttonId}`);

        if (myStoreButton) {
            if (options.hasAlert) {
                myStoreButton.classList.add(((content as any)[myStoreConstantName] as any).buttonAlertClass);

                if (svgCircleAlertEls && svgCircleAlertEls.length > 0) {
                    svgCircleAlertEls.forEach((el: Element) => {
                        el && el.classList && el.classList.remove(content.CLASSNAMES.hidden);
                    });
                }
            } else {
                myStoreButton.classList.remove(((content as any)[myStoreConstantName] as any).buttonAlertClass);

                if (svgCircleAlertEls.length > 0) {
                    svgCircleAlertEls.forEach((el: Element) => {
                        el && el.classList && el.classList.add(content.CLASSNAMES.hidden);
                    });
                }
            }
        }
    }

    public renderHeaderStoreAlerts(hasAlert: boolean): void {
        this.renderHeaderAlertInfoIcon(hasAlert);
        this.renderAlertLocationIcon({ hasAlert: hasAlert, type: 'HEADER' });
    }

    public renderFooterStoreAlerts(hasAlert: boolean): void {
        this.renderAlertLocationIcon({ hasAlert: hasAlert, type: 'FOOTER' });
    }

    public renderAll(force = false): void {
        this.renderHeaderStoreName();
        this.renderFooterStoreName();
        this.renderHeaderStoreAlerts(!!this.nearestStore.AlertMessage);
        this.renderFooterStoreAlerts(!!this.nearestStore.AlertMessage);

        this.headerMenu.render(force);
        this.footerMenu.render(force);

        if (!this.initialRenderingComplete) {
            this.initialRenderingComplete = true;
        }
    }

    public update(store: Store): void {
        this.nearestStore = store;
        storage.setStore(store);
        this.renderAll(true);

    }
}
